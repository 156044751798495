import { gql } from "@apollo/client"
import { PLATFORM_CAMPAIGN_FRAGMENT } from "../fragments"

export const DISABLE_CAMPAIGN_MANAGEMENT = gql`
  mutation DisableCampaignManagement(
    $clientId: Int!
    $platform: Platform!
    $platformIntegrationId: Int!
    $platformAdvertiserId: String!
    $platformCampaignId: ID!
  ) {
    disableCampaignManagement(
      clientId: $clientId
      platform: $platform
      platformIntegrationId: $platformIntegrationId
      platformAdvertiserId: $platformAdvertiserId
      platformCampaignId: $platformCampaignId
    ) {
      ...PlatformCampaignFragment
    }
  }
  ${PLATFORM_CAMPAIGN_FRAGMENT}
`
